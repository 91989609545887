<template>
  <div class="collection-list">
    <op-product-list
      ref="opProductList"
      :product-list="productList"
      :loading="loading"
      :total="total"
      @changePage="changePage"
    ></op-product-list>
  </div>
</template>

<script>
import { getProductList } from '@/api'
export default {
  props: {},
  data() {
    return {
      productList: [],
      total: 0,
      loading: false,
    }
  },

  computed: {},

  watch: {
    '$route.params.collectionId'(val) {
      if (val) {
        this.$refs.opProductList && this.$refs.opProductList.reloadData()
      }
    },
  },

  created() {
    this.getProductList()
  },

  methods: {
    async getProductList(pagination) {
      const defaultPagination = {
        categoryId: this.$route.params.collectionId,
        size: 12,
        start: 0,
      }
      try {
        this.loading = true
        const { data, totalRecords } = await getProductList({ ...defaultPagination, ...pagination })
        this.productList = data || []
        this.total = totalRecords
      } finally {
        this.loading = false
      }
    },
    changePage(pagination) {
      this.getProductList(pagination)
    },
  },
}
</script>
<style lang="less" scoped>
.collection-list {
  width: 1440px;
  max-width: calc(100% - 80px);
  margin: 0 auto;
}

@media (max-width: 1024px) {
  .collection-list {
    max-width: calc(100% - 80px);
  }
}
@media (max-width: 576px) {
  .collection-list {
    max-width: calc(100% - 32px);
  }
}
</style>
